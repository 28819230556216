class HeroTemplateGloRS extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		if (this.dataset.model) {
			// Padding top
			this.data.paddingTop =
				typeof this.data.paddingTop !== 'undefined'
					? this.data.paddingTop
					: '';

			// Padding bottom
			this.data.paddingBottom =
				this.data.paddingBottom === 'same-as-top'
					? this.data.paddingTop.replace('top', 'bottom')
					: this.data.paddingBottom;

			// Padding left
			this.data.paddingLeft =
				this.data.paddingLeft === 'same-as-top'
					? this.data.paddingTop.replace('top', 'left')
					: this.data.paddingLeft;

			// Padding right
			this.data.paddingRight =
				this.data.paddingRight === 'same-as-top'
					? this.data.paddingTop.replace('top', 'right')
					: this.data.paddingRight;
		}
		
		$(document).on('BATComponentsLoaded', async () => {
			const loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';
			if(!loggedIn && $(this).hasClass("hero-hog-contest")){
				$('.hero-hog-contest .bat-hero picture source').remove();
				$(".hero-hog-contest .bat-hero img").attr("src","/content/dam/glo-it/images/Hero-not-logged.svg")
				$(".hero-hog-contest .bat-hero .bat-headline").html("<h2> TANTI EVENTI DA NON PERDERE </h2><br><p> Vivi emozioni uniche con <b>House of Gloers</b></p>")
			}
		})
	}

	afterLoad() {
		this.$el = $(this);
		if (this.data.enableFullWidth) {
			this.$el.addClass('full-width');
		}
		if(!this.data.ctaAlignment || this.data.ctaAlignment === 'left') {
			this.$el.addClass('masthead-left');
		} else if(this.data.ctaAlignment === 'center') {
			this.$el.addClass('masthead-center');
		} else if(this.data.ctaAlignment === 'right') {
			this.$el.addClass('masthead-right');
		}
	}

	beforeUpdate() {
		// console.log('HeroTemplateDefault before update');
	}

	afterUpdate() {
		// console.log('HeroTemplateDefault after update');
	}

	unload() {
		// console.log('HeroTemplateDefault after unload');
	}
}

!customElements.get('bat-hero-glors') &&
	customElements.define('bat-hero-glors', HeroTemplateGloRS);
